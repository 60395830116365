import i18n from "i18n";

export const dateFormatOptions:any = [
    {
      label: i18n.t('User_Settings.dateFormats.DATE_TIME_AM_PM'),
      value: 'dd MMMM yyyy, hh:mm:ss a', 
    },
    {
      label: i18n.t('User_Settings.dateFormats.DATE_TIME_DMY'),
      value: 'dd MMMM yyyy, HH:mm:ss', 
    },
    {
      label: i18n.t('User_Settings.dateFormats.DATE_TIME_YMD'),
      value: 'yyyy/MM/dd HH:mm:ss', 
    },
  ];


  export const DATE_FORMATS_OPTIONS : any = {
    DATE_TIME_AM_PM: 'dd MMMM yyyy, hh:mm:ss a',
    DATE_TIME_DMY: 'dd MMMM yyyy, HH:mm:ss',
    DATE_TIME_YMD: 'yyyy/MM/dd HH:mm:ss',
  };
