import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
// import { useFetchWrapper as Api } from "../../utils";
import { getUserProfile, getUserMFA, getVerifyUserMFA, getVerifyUserMFAFirstTime } from '../../pages/authentication/login/components/api';
import { AUTHENTICATION, CONFIGURATION_API } from '../../components/constants/api-paths';
import { useFetchWrapper as Api } from '../../utils';
import { HTTP_STATUS } from '../../components/constants';
import { jwtDecode } from "jwt-decode";
import {
  setDasmidOptions,
  // setInitialFilter, 
  setMerchantData, setPaymentLoggroup, setReasonCodeList, 
  setUserPreference} from './gateway-config';
import { quickSort } from '../../utils/helper';
import { FILE_FORMATS_VALUES } from 'pages/user-settings/file-format';
import { DATE_FORMATS_OPTIONS } from 'pages/user-settings/date-format';

// const baseUrl = `${process.env.REACT_APP_API_URL}`;

// createAsyncThunk



export const saveProfile: any = createAsyncThunk(
  'auth/saveProfile',
  async (values
    , { dispatch }
  ) => {
    
    // const { data } = await Api().post(`/auth/signIn/`, values);
    const signInData = await getUserProfile(values);

    // after sign in calling preference api to get transaction columns

    const getUserPreference = async () => {
      const response: any = await fetch(`${process.env.REACT_APP_API_URL}/dasconfig/user-preferences`,
        {
          method: "GET",
          headers: {
            'Content-Type': 'application/json',
            'x-api-key': process.env.REACT_APP_X_API_KEY || "",
            Authorization: signInData?.token?.idToken
          },
        }
      )?.then(res => res.json());

      if (response && response?.data && response?.data?.length > 0 && response?.data[0]?.configuration?.formatType && response?.data[0]?.configuration?.transactionList?.list?.default) {
        const configuration = response?.data[0]?.configuration;
        dispatch(setUserPreference(configuration));
       }else{
        dispatch(setUserPreference({
          transactionList:{
            "selected": "default",
             list:{
              default:{
                "order":[],
                 "unChecked": []
              }
             },
          },
          statementList:{
            "selected": "default",
             list:{
              default:{
                "order":[],
                 "unChecked": []
              }
             },
          },
          dateFormatType:DATE_FORMATS_OPTIONS.DATE_TIME_AM_PM,
          formatType:FILE_FORMATS_VALUES.EXCEL
        }));
       }

      return true;
    };

    const userPreferenceFinished = await getUserPreference();
    if (userPreferenceFinished) {
      return signInData;
    }
  },
);

async function generateMFA(values: any) {

  const payload = {
    "Email": values,
    "path": "DASPOS"
  };

  try {
    const response = await Api().post(AUTHENTICATION.MFA_GENERATE_URL, payload);
    const mfaData = response.data;
    const QRCode = mfaData ? mfaData.qrcode : "";
    const SecretKey = mfaData ? mfaData.secret : "No secret available";
    localStorage.setItem("QRCode", QRCode);
    localStorage.setItem("SecretKey", SecretKey);
    return {QRCode, SecretKey};
  } catch (error) {
    console.error("API error:", error);
  }
}

export const checkMFA: any = createAsyncThunk(
  'auth/checkMFA',
  async ({ values, history }: any, { dispatch }: any) => {
    const data = await getUserMFA(values);
    const internvalUser = values.username?.includes("@paymentoptions.com");

    //Generate MFA QR
    const MFAData = !data?.IsMFA && await generateMFA(values.username);

    if (data?.IsMFAEnabled !== undefined && Object.keys(values).length > 0 && internvalUser)
      data?.IsMFAEnabled ? history.push({ pathname: '/mfa-setup', state: { isMFASetup: (data?.IsMFA ? true : false) } }) : dispatch(saveProfile(values));

    if (data?.IsMFAEnabled === false && !internvalUser)
      dispatch(saveProfile(values));


    const result = {
      ...data,
      ...values,
      ...MFAData
    };
    return result;
  },
);

export const verifyUserMFA: any = createAsyncThunk(
  'auth/verifyUserMFA',
  async (values: any) => {
    const data = await getVerifyUserMFA(values);
    return data;
  },
);

export const verifyMFAFirst: any = createAsyncThunk(
  'auth/verifyMFAFirstTime',
  async (values: any) => {
    const data = await getVerifyUserMFAFirstTime(values);
    return data;
  },
);

function transformChargebackReasonCode(input: any) {
  const output: any = {};

  for (const key in input) {
    // if (input?.hasOwnProperty(key)) {
    output[key] = input[key].map((item: any) => {
      const labelValue = `${item.ReasonCode} ${item.ReasonCodeDescription}`;
      return {
        "label": labelValue,
        "value": labelValue
      };
    });
    // }
  }

  return output;
}
export const getDasMidOptions: any = createAsyncThunk(
  'get/getDasMidOptions',
  async (values, { dispatch, getState }) => {
    const store: any = getState();
    const logginInAsMerchant = store?.auth?.profile?.Groups?.includes("CUSTOMER");
    const { accessLevel } = store?.auth?.profile;
    const response = await Api().get(CONFIGURATION_API);
    if ([HTTP_STATUS.OK]?.includes(response?.statusCode)) {
      const reasonCodeData = transformChargebackReasonCode(response?.data?.chargebackReasonCode);
      dispatch(setReasonCodeList(reasonCodeData));
      const dasmidOptions = response?.data?.merchantData?.map(({ DASMID }: any) => {
        return DASMID;
      })?.flat(1) || [];

      const dasmidList: any = dasmidOptions && dasmidOptions?.length > 0 ? quickSort([...new Set([...dasmidOptions])]) : [];
      dispatch(setDasmidOptions(dasmidList));

      const v2DasmidOptions = response?.data?.merchantData?.map(({ V2DASMID }: any) => {
        return V2DASMID;
      })?.flat(1)?.filter((item: any) => item) || [];
      const IsV2Merchant = response?.data?.merchantData?.find((item: any) => item.IsV2Merchant);

      // const merchantDetails = response?.data?.merchantData[0];
      if (v2DasmidOptions && v2DasmidOptions?.length > 0 && IsV2Merchant) {
        if (logginInAsMerchant && accessLevel === "ADMIN") {

          dispatch(setIsV2Merchant(true));
        }
        else if (!logginInAsMerchant) {
          dispatch(setIsV2Merchant(true));

        }
        else {
          dispatch(setIsV2Merchant(false));
        }
      }

      const v2dasmidList: any = v2DasmidOptions && v2DasmidOptions?.length > 0 ? [...new Set([...v2DasmidOptions])] : "";
      localStorage.setItem("v2DasmidOptions", v2dasmidList);
      if (!logginInAsMerchant) {
         dispatch(setPaymentLoggroup(response?.data?.configData?.payment_loggroup));
        const acquirerCode: any = response?.data?.acquirers || "";
        localStorage.setItem("acquirerCode", acquirerCode);
        const acquirerMIDData: any = quickSort(Object.values(response?.data?.acquirerMIDData)?.flat(1));
        localStorage.setItem("acquirerMIDData", acquirerMIDData);

        dispatch(setMerchantData(response?.data?.merchantData));
      }
      return response;
    } else {
      localStorage.setItem("dasmidOptions", "");
      localStorage.setItem("v2DasmidOptions", "");
      return response;
    }
  }
);

const initialState: any = {
  profile: undefined,
  userType: '',
  isMerchant: false,
  isReseller: false,
  isV2Merchant: false,
  firstTimeLogin: false,
  dasmidOptions: [],
  username: "",
  password: "",
  isMFAEnabled: false,
  isMFASetup: false,
  loggedInMerchant: false,
  QRCode: "",
  SecretKey: ""
};

// createSlice
export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setLoginDetails: (state, action) => {
      state.profile = action?.payload;
      if (action?.payload?.Groups?.includes('GUEST')) {
        state.userType = 'MERCHANT';
        state.isMerchant = true;
      } else if (action?.payload?.Groups?.includes('GUESTTHIRDPARTY')) {
        state.userType = 'RESELLER';
        state.isReseller = true;
      } else {
        state.userType = '';
      }
    },
    resetProfile: state => {
      state.profile = undefined;
      state.userType = '';
      state.isMerchant = false;
      state.isReseller = false;
      state.isV2Merchant = false;
      state.firstTimeLogin = false;
      state.isMFAEnabled = undefined;
      state.isMFASetup = undefined;
      state.username = "";
      state.password = "";
      state.loggedInMerchant = false;
      state.QRCode = "";
      state.SecretKey = "";
    },
    setIsV2Merchant: (state, action) => {
      state.isV2Merchant = action.payload;
    },
    setFirstTimeLogin: (state, action) => {
      state.firstTimeLogin = action.payload;
    },
    resetMFA: state => {
      state.isMFAEnabled = undefined;
      state.isMFASetup = undefined;
      state.QRCode = "";
      state.SecretKey = "";
    },
    resetUserCredentials: state => {
      state.username = "";
      state.password = "";
      state.loggedInMerchant = false;
    }
  },
  extraReducers: builder => {
    builder.addCase(saveProfile.fulfilled, (state, action) => {
      let jwtdecodeData: any = jwtDecode(action.payload.token.idToken);
      let merchantID: any = jwtdecodeData["custom:MerchantID"] || '';
      state.profile = { ...action.payload, merchantId: merchantID };
      state.firstTimeLogin = action.payload?.reset || false;
      if (action?.payload?.Groups?.includes('GUEST')) {
        state.userType = 'MERCHANT';
        state.isMerchant = true;
      } else if (action?.payload?.Groups?.includes('GUESTTHIRDPARTY')) {
        state.userType = 'RESELLER';
        state.isReseller = true;
      } else {
        state.userType = '';
      }
      if (action?.payload?.Groups?.includes('GUEST') ||
        action?.payload?.Groups?.includes('GUESTTHIRDPARTY') ||
        action?.payload?.Groups?.includes('CUSTOMER') ||
        action?.payload?.Groups?.includes('GUESTSCHEDULER') ||
        action?.payload?.Groups?.includes('THIRDPARTY')
      ) {
        state.loggedInMerchant = true;
      }
    });

    builder.addCase(checkMFA.fulfilled, (state, action) => {
      let isMFAEnabled: any = action.payload.IsMFAEnabled;
      let isMFASetup: any = action.payload.IsMFA;
      state.username = action.payload.username;
      state.password = action.payload.password;
      state.isMFAEnabled = isMFAEnabled;
      state.isMFASetup = isMFASetup;
      state.QRCode = action.payload.QRCode;
      state.SecretKey = action.payload.SecretKey;
    });
  },
});
export const authProfile = (state: any) => state?.auth?.profile;
export const authToken = (state: any) => state?.auth?.profile?.token;
export const isV2Merchant = (state: any) => state?.auth?.isV2Merchant;
export const firstTimeLogin = (state: any) => state?.auth?.firstTimeLogin;
export const authGroups = (state: any) => state?.auth?.profile?.Groups;
export const authEmail = (state: any) => state?.auth?.profile?.email;
export const authName = (state: any) => state?.auth?.profile?.name;
export const authAppLevel = (state: any) => state?.auth?.profile?.appLevel;
export const authUserRole = (state: any) => state?.auth?.profile?.accessLevel;
export const isMFAEnabled = (state: any) => state?.auth?.isMFAEnabled;
export const isMFASetup = (state: any) => state?.auth?.isMFASetup;
export const isMFAUsername = (state: any) => state?.auth?.username;
export const isMFAPassword = (state: any) => state?.auth?.password;
export const authSubsidiaries = (state: any) =>
  state?.auth?.profile?.subsidiaries;
export const authLastLogin = (state: any) =>
  state?.auth?.profile?.auth_time * 1000;
export const { resetProfile, setLoginDetails, setIsV2Merchant, setFirstTimeLogin, resetMFA, resetUserCredentials } = authSlice.actions;
export default authSlice.reducer;

